import React from 'react';
import classNames from 'classnames/bind';

const textSize = {
  sm: 'text-lg',
  md: 'text-xl',
  lg: 'text-2xl',
  xl: 'text-3xl',
};

export function connectionAvatar(connection, config = {}) {
  if (!connection?.icon_url && config.displayFallback === false) return null;

  const {
    size = 'md', bgColor = 'bg-white', containerSize = null, container = true, className = '',
  } = config;
  const heightWidth = {
    xxs: 'h-3 w-3',
    xs: 'h-4 w-4',
    sm: 'h-6 w-6',
    md: 'h-8 w-8',
    lg: 'h-10 w-10',
    xl: 'h-12 w-12',
  };

  const containerHeightWidth = {
    xxs: 'h-4 w-4',
    xs: 'h-6 w-6',
    sm: 'h-8 w-8',
    md: 'h-10 w-10',
    lg: 'h-12 w-12',
    xl: 'h-14 w-14',
  };

  // eslint-disable-next-line prefer-destructuring
  const iconUrl = connection?.icon_url;
  if (container === false) {
    return (
      <div className={className}>
        <img
          className={classNames(heightWidth[size] || heightWidth.md, 'rounded-md')}
          src={iconUrl}
          alt=""
        />
      </div>

    );
  }
  return (
    <div className={className}>
      { iconUrl
        ? (
          <span className={
            classNames(
              bgColor,
              containerHeightWidth[containerSize || size] || containerHeightWidth.md,
              'relative border border-gray-300 rounded grid place-items-center',
            )
          }
          >
            <img
              className={classNames(heightWidth[size] || heightWidth.md, 'rounded-md')}
              src={iconUrl}
              alt=""
            />
          </span>
        )
        : (
          <span
            className={
              classNames(
                'inline-flex items-center justify-center border border-gray-300 rounded',
                bgColor,
                containerHeightWidth[containerSize || size] || containerHeightWidth.md,
                textSize[size] || textSize.md,
              )
            }
          >
            <span className="font-bold leading-none text-indigo-700">
              {connection?.name ? connection.name[0] : null}
            </span>
          </span>
        )}
    </div>
  );
}

export function connectionEventSourceStatus({ connection, events = [], sourceLogs = [] }) {
  const { configuration_errors: configurationErrors, source } = connection;

  if (!source?.length) return 'not_supported';
  if (Object.keys(configurationErrors)?.length) return 'not_configured';
  if (events.length || sourceLogs.length) return 'active';
  return 'configured';
}

export function connectionName(connection) {
  return connection?.name || 'Untitled Connection';
}

export function connectionBaseUrl(connection) {
  return connection?.configuration?.url || '';
}

export function connectionApiEndpointStatus({ connection, destinationLogs = [] }) {
  const { configuration_errors: configurationErrors, destination } = connection;

  if (!destination?.length) return 'not_supported';
  if (Object.keys(configurationErrors)?.length) return 'not_configured';
  if (destinationLogs.length) return 'active';
  if (connection.api?.authentication?.record_type === 'oauth2' && connection.api.authentication.access_token === null) {
    return 'oauth_not_authenticated';
  }
  return 'configured';
}

export function connectionType(connection) {
  if (connection.connection_template?.name) {
    return connection.connection_template.name;
  }
  return null;
}

export function authType(connection) {
  if (connection.database && (connection.database?.username || connection.database?.password)) return 'Username/Password';
  switch (connection.api?.authentication?.record_type) {
    case 'oauth2':
      return 'OAuth 2.0';
    case 'bearer':
      return 'Bearer';
    case 'basic':
      return 'Basic';
    case 'api_key':
      return 'API Key';
    default:
      return 'None';
  }
}

export function apiConfigStatus(connection) {
  if (!connection.api?.authentication) return 'no_creds_required';
  switch (connection.api.authentication?.record_type) {
    case 'oauth2':
      if (connection.api.authentication.access_token) {
        return 'oauth2_installed';
      }
      return 'oauth2_pending';
    case 'bearer':
      if (connection.api.authentication.token) return 'has_creds';
      break;
    case 'basic':
      if (connection.api.authentication.username) return 'has_creds';
      break;
    case 'api_key':
      if (connection.api.authentication.key && connection.api.authentication.value) return 'has_creds';
      break;
    default:
      return null;
  }
  return null;
}

export function apiConfigStatusPretty(connection) {
  if (!connection.api?.authentication) return 'Ready';
  switch (connection.api.authentication?.record_type) {
    case 'oauth2':
      if (connection.api.authentication.access_token) {
        return 'App Installed';
      }
      return 'Pending App Install';
    case 'bearer':
      if (connection.api.authentication.token) return 'Ready';
      break;
    case 'basic':
      if (connection.api.authentication.username) return 'Ready';
      break;
    case 'api_key':
      if (connection.api.authentication.key && connection.api.authentication.value) return 'Ready';
      break;
    default:
      return null;
  }
  return null;
}

export function lastFour(field) {
  if (!field) return null;
  const finalFour = field.substr(-4);
  const length = field.length - 4;
  const displayLength = length > 20 ? 20 : length;
  const midChars = [...Array(displayLength)].map(() => '*');

  return (`${midChars.join('')}${finalFour}`);
}

export function configurationStatus(connection) {
  const sourceStatus = connectionEventSourceStatus({ connection });
  const destinationStatus = connectionApiEndpointStatus({ connection });
  const format = (status) => {
    switch (status) {
      case 'configured': return 'Configured';
      case 'not_configured': return 'Not configured';
      case 'not_supported': return 'Not supported';
      default: return '';
    }
  };

  if (sourceStatus === destinationStatus) {
    return `${format(sourceStatus)} as Event Source ${sourceStatus === 'configured' ? 'and' : 'or'} API Destination`;
  }
  return `${format(sourceStatus)} as Event Source, API Destination ${format(destinationStatus)}`;
}

export const formatHeaders = (connection) => {
  const formHeaders = connection?.configuration?.headers;
  if (!formHeaders) return connection;

  const headers = Object.fromEntries(formHeaders.map(({ key, value }) => [key, value]));
  const configuration = { ...connection.configuration, headers };

  return { ...connection, configuration };
};

export default {
  icon: connectionAvatar,
  inboundStatus: connectionEventSourceStatus,
  outboundStatus: connectionApiEndpointStatus,
  type: connectionType,
};
