/* eslint-disable camelcase */
/* eslint-disable react/no-unescaped-entities */
import React, { useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Footer from '../../../components/forms/Footer';
import Header from '../../../components/layout/PageHeader';
import mapErrorsToForm from '../../../helpers/FormHelpers';
import { AxiosContext } from '../../../context/AxiosContext';
import useMe from '../../../context/CurrentUserContext';
import Input from '../../../components/forms/InputBase';
import JSONInput from '../../../components/forms/JSONInput';

export default function Form() {
  const navigate = useNavigate();

  const { currentEntity } = useMe();
  const { name, metadata, icon_url } = currentEntity;
  const form = useForm({ defaultValues: { name, icon_url, metadata } });

  const { privateAxios } = useContext(AxiosContext);

  function update(callbacks = {}) {
    const mutation = async (params) => {
      const { data: { data } } = await privateAxios.patch('/entity', params);

      return data;
    };

    return useMutation({
      mutationFn: mutation,
      ...callbacks,
    });
  }

  const { isLoading: isSubmitting, mutate } = update(
    {
      onSuccess: () => navigate(-1),
      onError: (error) => mapErrorsToForm(error.response.data, form),
    },
  );

  const onSubmit = form.handleSubmit((entity) => mutate({ entity }));

  return (
    <form onSubmit={onSubmit}>
      <Header
        name={`Edit ${form.watch('name')}`}
        description={`Edit the ${currentEntity.name} Account`}
        // avatar={connectionAvatar(form.watch(), { size: 'lg' })}
        // icon={}
      />
      <div className="mx-auto max-w-3xl lg:max-w-7xl mt-10">
        <div className="w-full bg-white px-10 border border-gray-300 rounded mt-10">
          <div className="mt-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Entity  Details
            </h3>
          </div>
          <div className="mt-2 grid grid-cols-1 gap-y-2 gap-x-4 xl:grid-cols-2">
            <div className="lg:col-span-1">
              <Input
                form={form}
                label="Name"
                name="name"
                placeholder="Name..."
              />
            </div>
            <div className="lg:col-span-1">
              <Input
                form={form}
                label="Icon URL"
                name="icon_url"
                placeholder="Icon URL..."
              />
            </div>
          </div>
          <div className="mt-2 grid grid-cols-1 gap-y-2 gap-x-4 xl:grid-cols-2">
            <JSONInput form={form} label="Metadata" name="metadata" />
          </div>
          <Footer
            backButtonText="Back"
            onBackButtonClick={() => navigate(-1)}
            submitButtonText="Submit"
            submitButtonDisabled={isSubmitting}
            submitButtonType="submit"
          />
        </div>
      </div>
    </form>
  );
}
