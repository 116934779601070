import { DateTime, Duration } from 'luxon';
import Handlebars from 'handlebars';

export function coalesce(value, fallback) {
  if (value === null) return fallback;
  if (value === undefined) return fallback;

  return value;
}

Handlebars.registerHelper('coalesce', coalesce);

export function compact(array) {
  return array.filter((value) => value !== null && value !== undefined);
}

Handlebars.registerHelper('compact', compact);

export function concat(...rest) {
  return rest
    .slice(0, -1)
    .join('');
}

Handlebars.registerHelper('concat', concat);

export function extract(regex, value) {
  if (typeof value === 'string') {
    const extracted = value.match(regex);
    if (extracted === null) return '';
    return extracted[0];
  }

  return '';
}

Handlebars.registerHelper('extract', extract);

export function filterWithKey(array, key) {
  return array.filter((item) => item[key]);
}

Handlebars.registerHelper('filterWithKey', filterWithKey);

export function findByKey(array, key, value) {
  return array.find((item) => item[key] === value);
}

Handlebars.registerHelper('findByKey', findByKey);

export function first(array) {
  return array[0];
}

Handlebars.registerHelper('first', first);

export function flatten(array) {
  return array.flat();
}

Handlebars.registerHelper('flatten', flatten);

export function formatDate(date, format) {
  return date.toFormat(format);
}

Handlebars.registerHelper('formatDate', formatDate);

export function join(array, separator) {
  return array.join(separator);
}

Handlebars.registerHelper('join', join);

export function json(value) {
  return JSON.stringify(value);
}

Handlebars.registerHelper('json', json);

export function keys(map) {
  return Object.keys(map);
}

Handlebars.registerHelper('keys', keys);

export function last(array) {
  return array[array.length - 1];
}

Handlebars.registerHelper('last', last);

export function mapping(map, key, ...rest) {
  const defaultValue = rest.length === 2 ? rest[0] : '';
  if (!map) return '';
  return `${map[key] || defaultValue}`;
}

Handlebars.registerHelper('mapping', mapping);

export function merge(...rest) {
  if (rest.length === 3) {
    const [original, updated] = rest;
    return {
      ...original,
      ...updated,
    };
  }

  if (rest.length === 2) {
    const [original, { hash }] = rest;
    return {
      ...original,
      ...hash,
    };
  }

  throw new Error('Expected 2 arguments.');
}

Handlebars.registerHelper('merge', merge);

export function object({ hash }) {
  return hash;
}

Handlebars.registerHelper('object', object);

export function replace(string, oldValue, newValue) {
  return string.replace(oldValue, newValue);
}

Handlebars.registerHelper('replace', replace);

export function replaceAll(string, oldValue, newValue) {
  return string.replaceAll(oldValue, newValue);
}

Handlebars.registerHelper('replaceAll', replaceAll);

export function reverse(array) {
  return array.reverse();
}

Handlebars.registerHelper('reverse', reverse);

export function slice(array, start, end) {
  return array.slice(start, end);
}

Handlebars.registerHelper('slice', slice);

export function sort(array) {
  return array.sort();
}

Handlebars.registerHelper('sort', sort);

export function timeAgo(string) {
  const delta = Duration.fromISO(string);

  return DateTime
    .now()
    .minus(delta);
}

Handlebars.registerHelper('timeAgo', timeAgo);

export function timeFrom(string) {
  const delta = Duration.fromISO(string);

  return DateTime
    .now()
    .plus(delta);
}

Handlebars.registerHelper('timeFrom', timeFrom);

export function trim(string) {
  if (!string) return '';

  return string.trim();
}

Handlebars.registerHelper('trim', trim);

export function trimEnd(string) {
  return string.trimEnd();
}

Handlebars.registerHelper('trimEnd', trimEnd);

export function trimStart(string) {
  return string.trimStart();
}

Handlebars.registerHelper('trimStart', trimStart);

export function uniq(array) {
  return [...new Set(array || [])];
}

Handlebars.registerHelper('uniq', uniq);

export function urlEncode(value) {
  return encodeURIComponent(value);
}

Handlebars.registerHelper('urlEncode', urlEncode);

export function values(map) {
  return Object.values(map);
}

Handlebars.registerHelper('values', values);

function escape(text) {
  return text.replaceAll('"', '\\"');
}

Handlebars.Utils.escapeExpression = (text) => {
  if (text === null) return '';
  if (text === undefined) return '';

  if (text instanceof DateTime) {
    return text.toISO({
      includeOffset: false,
      suppressMilliseconds: true,
    });
  }

  // if (text === undefined) return 'undefined';
  if (typeof text === 'object') return json(text);
  if (typeof text === 'string') return escape(text);
  return text;
};

function render({ template, variables }) {
  if (template === null) return '';
  if (template === undefined) return '';

  if (Array.isArray(template)) {
    const [, body] = template;

    return Handlebars.compile(body)(variables);
  }

  if (typeof template === 'object') {
    const { body } = template;

    return Handlebars.compile(body)(variables);
  }

  return Handlebars.compile(template)(variables);
}

function addAliases(records) {
  if (Object.keys(records).includes('action')) {
    const variables = { ...records };
    delete variables.action;

    const alias = Object.fromEntries(
      Object.entries(records.action.alias || [])
        .map(([key, value]) => {
          if (typeof value === 'string') {
            const rendered = render({ template: value, variables });

            return [key, rendered];
          }

          return [key, value];
        }),
    );

    return {
      ...records,
      alias,
    };
  }

  return records;
}

export default function renderHandlebars({ records, templates }) {
  const variables = addAliases(records);

  return templates.map((template) => render({ template, variables }));
}
